var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"subscribe"},[_c('div',{staticClass:"module"},[_c('div',{staticClass:"title"},[_vm._v("参观团信息")]),_c('div',{staticClass:"info"},[_c('div',{staticClass:"row",staticStyle:{"align-items":"center"}},[_c('div',{staticClass:"label"},[_vm._v("参观日期")]),_c('div',{staticClass:"content"},[_vm._v(_vm._s(_vm.groupData.date))])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"label"},[_vm._v("参观场馆")]),_c('div',{staticClass:"content"},[_c('div',{staticStyle:{"margin-top":"1px"}},[_vm._v(_vm._s(_vm.groupData.venue_name))]),_c('div',{staticClass:"addr"},[_c('img',{staticClass:"pos",attrs:{"src":"https://khome2.tuzuu.com/klfront/position.png"}}),_c('span',[_vm._v(_vm._s(_vm.groupData.venue_addr))])])])]),_c('div',{staticClass:"row",staticStyle:{"align-items":"center"}},[_c('div',{staticClass:"label"},[_vm._v("导游")]),_c('div',{staticClass:"content"},[_vm._v(_vm._s(_vm.groupData.guide))])])])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}],staticClass:"module write"},[_c('div',{staticClass:"title"},[_vm._v("填写预约信息")]),_c('van-form',{on:{"submit":_vm.handleSubmit}},[_vm._l((_vm.formData),function(it,i){return _c('div',{key:i,staticClass:"person"},[(i !== 0)?_c('div',{staticClass:"peers between"},[_c('div',[_vm._v("同行人 "+_vm._s(i))]),_c('div',{staticClass:"delete",on:{"click":function($event){return _vm.deletePeers(i)}}},[_vm._v("删除")])]):_vm._e(),_c('van-field',{staticClass:"qrcode",attrs:{"value":_vm.healthImgArr[i],"label":"健康码截图","rules":[{ required: true, message: '请上传健康码截图' }]},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('div',[_vm._v("健康码小程序，截图健康码页面")]),_c('div',{staticClass:"upload center",style:({
                            border: _vm.healthImgArr[i] ? 'none': ''
                        })},[(_vm.healthImgArr[i])?_c('van-image',{attrs:{"src":_vm.healthImgArr[i],"fit":"cover"}}):_c('i',[_vm._v("+")]),_c('input',{staticClass:"upload-input",attrs:{"type":"file"},on:{"change":function($event){return _vm.handleUpload($event, i)}}})],1)]},proxy:true}],null,true)}),_c('van-field',{attrs:{"label":"姓名","placeholder":"请输入真实姓名","rules":[{ required: true, message: '请输入真实姓名' }]},on:{"input":_vm.debounceHandleInput},model:{value:(it.name),callback:function ($$v) {_vm.$set(it, "name", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"it.name"}}),_c('van-field',{attrs:{"type":"tel","label":"手机号","placeholder":"请输入有效手机号","rules":[
                        { required: true, message: '请输入手机号' },
                        { pattern: /1\d{10}$/, message: '请输入有效手机号' }
                    ]},on:{"input":_vm.debounceHandleInput},model:{value:(it.mobile),callback:function ($$v) {_vm.$set(it, "mobile", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"it.mobile"}}),_c('van-field',{attrs:{"label":"身份证号","type":"tel","placeholder":"请填写真实身份证号，用于预约","rules":[
                        { required: true, message: '请填写真实身份证号' },
                        { validator: (val) => _vm.validIdentity(val), message: '请填写有效身份证号' }
                    ]},on:{"input":_vm.debounceHandleInput},model:{value:(it.id_no),callback:function ($$v) {_vm.$set(it, "id_no", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"it.id_no"}})],1)}),_c('van-button',{staticClass:"add-peers",attrs:{"plain":"","block":"","native-type":"button"},on:{"click":_vm.addPeers}},[_c('i',[_vm._v("+")]),_vm._v("添加同行人 ")]),_c('van-button',{staticClass:"submit",attrs:{"color":"linear-gradient(270deg, #17ad84 0%, #57d6bd 100%)","block":"","native-type":"submit"}},[_vm._v(" 提交预约申请 ")])],2)],1),(_vm.showConfirmInfo)?_c('div',{staticClass:"confirm-info-container center"},[_c('div',{staticClass:"confirm-info"},[_c('h1',[_vm._v("确认预约信息")]),_c('div',{staticClass:"info"},_vm._l((_vm.formData),function(it,i){return _c('div',{key:i,staticClass:"person"},[_c('div',{staticClass:"row flex"},[_c('div',{staticClass:"label"},[_vm._v("姓名")]),_c('div',[_vm._v(_vm._s(it.name))])]),_c('div',{staticClass:"row flex"},[_c('div',{staticClass:"label"},[_vm._v("手机号")]),_c('div',[_vm._v(_vm._s(it.mobile))])]),_c('div',{staticClass:"row flex"},[_c('div',{staticClass:"label"},[_vm._v("身份证号")]),_c('div',[_vm._v(_vm._s(it.id_no))])])])}),0),_vm._m(0),_c('div',{staticClass:"btns flex"},[_c('van-button',{attrs:{"color":"#F7F5F6"},on:{"click":function($event){_vm.showConfirmInfo = false}}},[_vm._v("修改")]),_c('van-button',{attrs:{"color":"linear-gradient(270deg, #17ad84 0%, #57d6bd 100%)"},on:{"click":_vm.submit}},[_vm._v("确认提交")])],1)])]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tip flex"},[_c('i'),_c('span',[_vm._v("请确保以上信息准确，以便为您尽快预约")])])
}]

export { render, staticRenderFns }