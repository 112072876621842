<template>
    <div class="subscribe">
        <div class="module">
            <div class="title">参观团信息</div>

            <div class="info">
                <div class="row" style="align-items: center;">
                    <div class="label">参观日期</div>
                    <div class="content">{{ groupData.date }}</div>
                </div>
                <div class="row">
                    <div class="label">参观场馆</div>
                    <div class="content">
                        <div style="margin-top: 1px;">{{ groupData.venue_name }}</div>
                        <div class="addr">
                            <img class="pos" src="https://khome2.tuzuu.com/klfront/position.png" />
                            <span>{{ groupData.venue_addr }}</span>
                        </div>
                    </div>
                </div>
                <div class="row" style="align-items: center;">
                    <div class="label">导游</div>
                    <div class="content">{{ groupData.guide }}</div>
                </div>
            </div>
        </div>

        <div class="module write" v-show="!loading">
            <div class="title">填写预约信息</div>

            <van-form @submit="handleSubmit">
                <div v-for="(it, i) in formData" class="person" :key="i">
                    <div class="peers between" v-if="i !== 0">
                        <div>同行人 {{ i }}</div>
                        <div class="delete" @click="deletePeers(i)">删除</div>
                    </div>
                    <van-field class="qrcode" :value="healthImgArr[i]" label="健康码截图" :rules="[{ required: true, message: '请上传健康码截图' }]">
                        <template #input>
                            <div>健康码小程序，截图健康码页面</div>
                            <div class="upload center" :style="{
                                border: healthImgArr[i] ? 'none': ''
                            }">
                                <van-image v-if="healthImgArr[i]" :src="healthImgArr[i]" fit="cover" />
                                <i v-else>+</i>
                                <input class="upload-input" type="file" @change="handleUpload($event, i)" />
                            </div>
                        </template>
                    </van-field>
                    <van-field
                        v-model.trim="it.name"
                        label="姓名"
                        placeholder="请输入真实姓名"
                        @input="debounceHandleInput"
                        :rules="[{ required: true, message: '请输入真实姓名' }]"
                    />
                    <van-field
                        v-model.trim="it.mobile"
                        type="tel"
                        label="手机号"
                        placeholder="请输入有效手机号"
                        @input="debounceHandleInput"
                        :rules="[
                            { required: true, message: '请输入手机号' },
                            { pattern: /1\d{10}$/, message: '请输入有效手机号' }
                        ]"
                    />
                    <van-field
                        v-model.trim="it.id_no"
                        label="身份证号"
                        type="tel"
                        placeholder="请填写真实身份证号，用于预约"
                        @input="debounceHandleInput"
                        :rules="[
                            { required: true, message: '请填写真实身份证号' },
                            { validator: (val) => validIdentity(val), message: '请填写有效身份证号' }
                        ]"
                    />
                </div>

                <van-button class="add-peers" plain block native-type="button" @click="addPeers"> <i>+</i>添加同行人 </van-button>

                <van-button class="submit" color="linear-gradient(270deg, #17ad84 0%, #57d6bd 100%)" block native-type="submit">
                    提交预约申请
                </van-button>
            </van-form>
        </div>

        <div class="confirm-info-container center" v-if="showConfirmInfo">
            <div class="confirm-info">
                <h1>确认预约信息</h1>
                <div class="info">
                    <div class="person" v-for="(it, i) in formData" :key="i">
                        <div class="row flex">
                            <div class="label">姓名</div>
                            <div>{{ it.name }}</div>
                        </div>
                        <div class="row flex">
                            <div class="label">手机号</div>
                            <div>{{ it.mobile }}</div>
                        </div>
                        <div class="row flex">
                            <div class="label">身份证号</div>
                            <div>{{ it.id_no }}</div>
                        </div>
                    </div>
                </div>
                <div class="tip flex">
                    <i></i>
                    <span>请确保以上信息准确，以便为您尽快预约</span>
                </div>
                <div class="btns flex">
                    <van-button @click="showConfirmInfo = false" color="#F7F5F6">修改</van-button>
                    <van-button @click="submit" color="linear-gradient(270deg, #17ad84 0%, #57d6bd 100%)">确认提交</van-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SparkMD5 from "spark-md5";
import { Dialog } from "vant";

import { debounce, validIdentity } from "@/utils/util";

function getFileReader(file, type = "base64") {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
        if (type === "base64") {
            reader.readAsDataURL(file);
        } else if (type === "binary") {
            reader.readAsBinaryString(file);
        }
    });
}

export default {
    data() {
        return {
            formData: [
                {
                    name: "",
                    mobile: "",
                    id_no: "",
                },
            ],
            qiniu: {
                up_token: "",
                upload_url: "",
            },
            healthImgArr: [],
            loading: true,
            group_id: "",
            groupData: {},
            showConfirmInfo: false,
        };
    },
    created(){
        const group_id = this.$route.query.group_id;
        if (!group_id) {
            this.$router.push("/home");
        } else {
            this.group_id = group_id;
            this.fetchData();

            // 防抖输入事件 
            this.debounceHandleInput = debounce(this.setFormDataToStorage, 300);

            let formData = localStorage.getItem('j_formData');
            if (formData) {
                formData = JSON.parse(formData);
                this.formData = formData;
            }
        }
    },
    methods: {
        validIdentity,
        async fetchData() {
            this.loading = true;
            axios.get('/get_guide_group_info', { params: { group_id: this.group_id } }).then((res) => {
                this.groupData = res.data;
            })
            const res = await axios.get("/getuploadtoken");
            this.qiniu = res.data;
            this.loading = false;
        },
        handleSubmit() {
            this.showConfirmInfo = true;
        },
        async submit() {
            console.log(this.group_id);
            const res = await axios.post("/up_reservinfo", {
                formData: this.formData,
                group_id: this.group_id
            });
            if (res.code == 1) {
                localStorage.removeItem('j_formData');
                this.$toast({
                    message: '提交成功',
                    forbidClick: true,
                    duration: 1000,
                    onClose: () => {
                        this.$router.push('/visit');
                    }
                })
            } else {
                this.$toast(res.message)
            }
        },
        addPeers() {
            this.formData.push({
                name: "",
                mobile: "",
                id_no: "",
            });
        },
        async handleUpload(e, i) {
            const file = e.target.files[0];
            const size = file.size / 1024 / 1024;
            const ext = file.name.split(".").pop();
            const whiteList = ["png", "jpeg", "jpg"];
            if (!whiteList.includes(ext)) {
                this.$toast.fail("图片类型不支持");
                return;
            }
            if (size > 5) {
                this.$toast.fail("文件最大5MB");
                return;
            }
            const toast = this.$toast({
                duration: 0,
                message: "上传中...",
            });
            
            let key = await getFileReader(file, "binary");
            key = SparkMD5.hashBinary(key);
            key = `images/${key}.${ext}`;
            this.formData[i].health_img = key;

            const formData = new FormData();
            formData.append("key", key);
            formData.append("token", this.qiniu.up_token);
            formData.append("file", file);
            const res = await axios.post(this.qiniu.upload_url, formData);
            if (res.hash && res.key) {
                getFileReader(file).then(base64 => {
                    this.healthImgArr.splice(i, 1, base64);
                });
                toast.message = "上传成功";
            } else {
                toast.message = "上传失败";
            }
            setTimeout(() => {
                toast.clear();
            }, 1500);
            
        },
        // 删除同行人
        deletePeers(i) {
            console.log(i);
            Dialog.confirm({
                title: '确认删除该同行人吗？',
                className: 'delete-dialog'
            }).then(() => {
                this.formData.splice(i, 1);
                this.healthImgArr.splice(i, 1);
                this.setFormDataToStorage();
            }).catch(() => {});
        },
        setFormDataToStorage() {
            localStorage.setItem('j_formData', JSON.stringify(this.formData));
        }
    },
};
</script>

<style lang="less">
.delete-dialog {
    border-radius: 5px;
    .van-dialog__header--isolated {
        padding: 34px 0;
    }
}
</style>

<style lang="less" scoped>
.subscribe {
    background: #f6f6f6;
    min-height: 100vh;
}
.module {
    background: #fff;
    padding: 15px;
    .title {
        color: #232323;
        font-size: 16px;
        font-weight: bold;
    }
    .info {
        margin-top: 18px;
        font-size: 14px;
        .row {
            display: flex;
            margin-top: 10px;
            .label {
                white-space: nowrap;
                width: 60px;
                margin-right: 15px;
                color: #666666;
            }
            .pos {
                width: 15px;
                height: 19px;
                margin-right: 5px;
            }
            .addr {
                display: flex;
                margin-top: 10px;
            }
        }
    }
}

.van-cell {
    padding: 18px 0;
    &::after {
        left: 0;
        right: 0;
    }
    &.qrcode /deep/ .van-field__control {
        flex-direction: column;
        align-items: flex-start;
        color: #999 !important;
    }
    &.qrcode::after {
        display: none;
    }
    /deep/ .van-field__label {
        width: 70px;
        span {
            color: #353334;
        }
    }
    /deep/ .van-field__control::placeholder {
        color: #999;
    }
    &:last-child::after {
        display: block;
    }
}
.submit {
    border-radius: 6px;
    color: #fff;
    margin-top: 20px;
    height: 40px;
}
.write {
    min-height: calc(100vh - 186px);
    box-sizing: border-box;
    margin-top: 11px;
    .person:not(:first-of-type) {
        margin-top: 20px;
    }
    .peers {
        font-weight: bold;
        font-size: 15px;
        .delete {
            color: #ee0a24;
            font-weight: lighter;
        }
    }
    .add-peers {
        border: 1px dashed #21b48d;
        color: #21b48d;
        width: min-content;
        margin: 0 auto;
        white-space: nowrap;
        margin-top: 30px;
        i {
            margin-right: 10px;
        }
    }
    .upload {
        position: relative;
        width: 80px;
        height: 80px;
        border-radius: 3px;
        border: 1px solid #979797;
        color: #666;
        font-size: 30px;
        overflow: hidden;
        box-sizing: border-box;
        margin-top: 15px;
        .van-image {
            width: 100%;
            height: 100%;
        }
    }
}

.upload-input {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
}

.confirm-info-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    font-size: 14px;
    z-index: 1;
    .confirm-info {
        background: #fff;
        border-radius: 5px;
        width: 90%;
        padding: 20px 15px;
        box-sizing: border-box;
    }
    h1 {
        color: #232323;
        font-size: 16px;
        margin-bottom: 15px;
        text-align: center;
    }
    .info {
        background: #F8F8F8;
        border-radius: 5px;
        max-height: 260px;
        overflow-y: auto;
        padding: 0 10px;
        color: #232323;
    }
    .person:not(:last-of-type) {
        border-bottom: 1px solid #EAEAEA;
    }
    .row {
        height: 40px;
    }
    .label {
        color: #666666;
        white-space: nowrap;
        width: 60px;
        margin-right: 15px;
    }
    .tip {
        color: #666666;
        margin: 15px 0 37px;
        i {
            display: inline-block;
            width: 16px;
            height: 16px;
            margin-right: 5px;
            background: url('https://khome2.tuzuu.com/klfront/tip-icon.png') no-repeat left top/100% 100%;
        }
    }
    .btns {
        .van-button {
            flex: 1 1 50%;
            border-radius: 5px;
            &:first-of-type {
                margin-right: 10px;
                color: #353334 !important;
            }
        }
    }
}

</style>
